.activate-content {
    margin-top: -120px;

    .activate-text p {
        white-space: pre-line;
        font-size: 1.2rem;

    }

    .resend-action {
        cursor: pointer;
        color: #25aaff;

        &:hover {
            color: #4499ca;
        }

        &:active {
            color: #999;
        }

        &.resend-disabled {
            opacity: 0.6;
            cursor: not-allowed;

            &:hover {
                color: #25aaff;
            }
    
            &:active {
                color: #25aaff;
            }
        }
    }

    .activate-btns {
        display: flex;
        justify-content: flex-end;
    }
}

.credentials-content {
    .label-wrap {
        display: flex;
        align-items: center;
    }

    .label-text {
        font-size: 28px;
        
        &.register {
            font-size: 15px;
        }
    }

    .show-password {
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 2%;
    }
}