.wrap-phone-number {
    width: 100%;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    padding: 3%;
    align-items: center;

    .area-code-wrap{
        margin: 0;
        width: 100px;

        //overide select styles
        .css-13cymwt-control, .css-t3ipsp-control{
            height: calc(1.5em + 1.25rem + 2px);
            font-size: 0.875rem;
            border: 1px solid #cad1d7;
            border-radius: 0.375rem;
        }
        .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer{//select arrow
            padding: 0;
        }
        .css-hlgwow {
            padding: 2px 5px;
        }
        .css-1dimb5e-singleValue{
            color: #8898aa;
            font-weight: 400;
        }
    }
}
