.cursor-pointer{
    cursor: pointer;
}

.cursor-text{
    cursor: text;
}

.pointer-events-none{
    pointer-events: none;
}
.theme-link{
    color: #5EB6E8;
    font-weight: bold;
    cursor: pointer;
}

.backslash-n-break{
    white-space: pre-line;
}

.column-break {
    break-before: column
}

.break-line-flex{
    width: 100%;
}

.cursor-pointer{
    cursor: pointer;
}

.arrow-up{
    transform: rotate(180deg);
}

.flex-1{
    flex: 1;
}

.divider-x{
    border-left: solid 1px black;
}

span.page-title {
    display: block;
    border-bottom: solid 1px #023F7F;
    width: max-content;
    min-width: 45%;
    font-size: 1.5rem;
    color: #023F7F;
    padding: 0.3%;
}

.table-fix-head{
    overflow: auto;
}

.table-fix-head thead th {
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: white;
}